import { useQuery } from "@tanstack/react-query";
import { DiceThree, Plus } from "iconoir-react";
import { useState } from "react";
import SelectBox from "../../component/custom-form/SelectBox";
import MasonryLayout from "../../component/MasonaryLayout";
import TrendingPostCard from "../../component/TrendingPostCard";
import MainLayout from "../../layout/main";
import { fetchTrendingGroup, fetchTrendingPost } from "../../services/trending.service";
import AddMedia from "./addMedia";

const contentToneOption = [
    {
        label: 'Funny',
        value: 'funny',
    },
    {
        label: 'Insightful',
        value: 'insightful',

    },
    {
        label: 'Relatable',
        value: 'shareworthy',
    },
]

const contentSourceOption = [
    {
        label: 'Linkedin',
        value: 'linkedin',
    },
    {
        label: 'Twitter',
        value: 'twitter',

    },
]
export const sortByOption = [
    {
        label: 'Latest first',
        value: 'publishedDate:desc',
    },
    {
        label: 'Oldest first',
        value: 'publishedDate:asc',
    },
    {
        label: 'Likes (high - low)',
        value: 'totalLikeCount:desc',
    },
    {
        label: 'Engagement (high - low)',
        value: 'commentsCount:desc',
    },
]
const TrendingPost = () => {
    const [page, setPage] = useState(1);
    const [topicGroupFilter, setTopicGroupFilter] = useState({});
    const [contentToneFilter, setContentToneFilter] = useState({});
    const [contentSourceFilter, setContentSourceFilter] = useState({});
    const [sortBy, setSortBy] = useState({});
    const query = {
        limit: 24,
        topicGroupId: topicGroupFilter.value,
        reactionFunny: contentToneFilter.value === 'funny' ? true : undefined,
        reactionInsightful: contentToneFilter.value === 'insightful' ? true : undefined,
        reactionShareworthy: contentToneFilter.value === 'shareworthy' ? true : undefined,
        page,
        source: contentSourceFilter.value,
        sortBy: sortBy.value,
    }
    const trendingGroupData = useQuery({
        queryKey: ['trending-group', {}],
        queryFn: async ({ queryKey }) => fetchTrendingGroup(queryKey[1]),
        staleTime: Infinity,
        retry: false,
    });

    let topicGroupOption = trendingGroupData?.data?.data?.map((item) => {
        return {
            label: `${item.groupName} | ${item.region}`,
            value: item._id
        }
    }) || [];

    const { data, isLoading } = useQuery({
        queryKey: ['trending-post', query],
        queryFn: async ({ queryKey }) => fetchTrendingPost(queryKey[1]),
        staleTime: Infinity,
        retry: false,
    });

    return (
        <MainLayout>
            <div className="container rounded-xl min-h-[97vh] bg-star-light3 mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6 text-gray-800">Trending Post ({data?.pagination?.totalItems || 0})</h1>
                <div className="flex justify-between mb-4 items-center">
                    <div className="flex items-center gap-4">
                        <SelectBox
                            value={topicGroupFilter}
                            label="Topic Group"
                            options={topicGroupOption}
                            onRemoveFilter={() => setTopicGroupFilter({})}
                            onChange={setTopicGroupFilter}
                        />
                        <SelectBox
                            value={contentToneFilter}
                            // icon={<MicstageIcon />}
                            label="Content Tone"
                            options={contentToneOption}
                            onRemoveFilter={() => { setContentToneFilter({}) }}
                            onChange={setContentToneFilter}
                        />
                        <SelectBox
                            value={contentSourceFilter}
                            // icon={<MicstageIcon />}
                            label="Content Source"
                            options={contentSourceOption}
                            onRemoveFilter={() => { setContentSourceFilter({}) }}
                            onChange={setContentSourceFilter}
                        />
                        <div className='ml-auto mr-4'>
                            <SelectBox
                                value={sortBy}
                                label="Sort"
                                options={sortByOption}
                                onRemoveFilter={() => { setSortBy({}) }}
                                onChange={(val) => {
                                    setSortBy(val);
                                }}
                                removeBtnText='Remove' // optional
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <MasonryLayout>
                        {
                            data?.data?.map((postItem) => (
                                <TrendingPostCard {...postItem} />
                            ))
                        }
                    </MasonryLayout>
                </div>
                {
                    !isLoading && data?.pagination?.remainingPage > 0 && (
                        <div className='flex items-center justify-center my-4'>
                            <button className='outlined-btn small border-none' onClick={() => setPage((prev) => prev + 1)}>
                                <DiceThree />
                                Renew Trending feed
                            </button>
                        </div>
                    )
                }
            </div>
        </MainLayout>
    )
}
export default TrendingPost;