import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { toast } from "react-toastify";

// Icon imports
import { EditPencil, Globe, MoreVert, NavArrowDown, NavArrowRight, Plus, Search, TrashSolid } from "iconoir-react";

// Component imports
import SelectBox from "../../component/custom-form/SelectBox";
import MainLayout from "../../layout/main";
import AddTrendingTopic from "./addTrendingTopic";

// Service imports
import { deleteTrendingTopicGroup, fetchTrendingTopic } from "../../services/trending.service";
import moment from "moment";
import ActionPopup from "./actionPopup";
import EditTrendingTopic from "./editTrendingTopic";

const regionFilterOption = [
    { label: 'India', value: 'india' },
    { label: 'Outside India', value: 'outside_india' },
];

const Trending = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [regionFilter, setRegionFilter] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedEditGroup, setSelectedEditGroup] = useState({});
    const [expandedGroups, setExpandedGroups] = useState({});

    const query = {
        page: 1,
        limit: 10,
        search: searchTerm,
        region: regionFilter.value,
    };

    const { data, refetch } = useQuery({
        queryKey: ['trending-topic', query],
        queryFn: async ({ queryKey }) => fetchTrendingTopic(queryKey[1]),
        staleTime: Infinity,
        retry: false,
    });

    const { mutate } = useMutation({
        mutationFn: deleteTrendingTopicGroup,
        onSuccess: () => {
            toast.success("Topic deleted successfully");
            refetch();
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong');
        },
    });

    const handleDeleteTopic = (topicId) => mutate(topicId);

    const toggleGroup = (groupId) => {
        setExpandedGroups((prev) => ({
            ...prev,
            [groupId]: !prev[groupId],
        }));
    };


    return (
        <MainLayout>
            {openEditDialog && <EditTrendingTopic onClose={() => { setOpenEditDialog(false) }} data={selectedEditGroup} />}
            {openDialog && <AddTrendingTopic onClose={() => setOpenDialog(false)} />}
            <div className="container rounded-xl min-h-[97vh] bg-star-light3 mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6 text-gray-800">Trending Topics</h1>

                <div className="flex justify-between">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search topic..."
                            className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            aria-label="Search topics"
                        />
                        <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                    </div>
                    <div className="flex items-center gap-8">
                        <SelectBox
                            value={regionFilter}
                            icon={<Globe color="#FFA79E" />}
                            label="Region"
                            options={regionFilterOption}
                            onRemoveFilter={() => setRegionFilter({})}
                            onChange={setRegionFilter}
                        />
                        <button
                            className="filled-btn h-full small"
                            onClick={() => setOpenDialog(true)}
                        >
                            <Plus /> Add Topic
                        </button>
                    </div>
                </div>

                <div className="mt-10">
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {/* <div className="text-sm font-medium text-gray-900"><NavArrowRight /></div> */}
                                    </td>
                                    {['Group Name', 'Region', 'Start Date', 'Total Post', 'Total Images', 'Status', 'Actions'].map((header, index) => (
                                        <th
                                            key={index}
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {data?.data?.topicGroups?.map((group) => (
                                    <React.Fragment key={group._id}>
                                        <tr
                                            className="hover:bg-gray-50 cursor-pointer"
                                            onClick={() => toggleGroup(group._id)}
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-medium text-gray-900">
                                                    {expandedGroups[group._id] ? <NavArrowDown /> : <NavArrowRight />}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-medium text-gray-900">{group.groupName}
                                                    {group.hotTopic &&
                                                        <span className="px-2 border ml-2 py-1 bg-orange-200 text-orange-600 text-xs font-semibold rounded-lg">
                                                            Hot Topic 🔥
                                                        </span>
                                                    }</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900 capitalize">{Array.isArray(group.region) ? group.region.join(' / ') : group.region}</div>
                                            </td>
                                            <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                {group.startDate ? moment(group.startDate).format('MMM DD, YYYY') : "-"}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-medium text-gray-900">{group.totalPost}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900 capitalize">{group.totalImages}</div>
                                            </td>
                                            <td>
                                                <span
                                                    className={`px-2 py-1 rounded-full text-xs ${group.isActive ? 'bg-green-100 text-green-900' : 'bg-red-100 text-red-900'
                                                        }`}
                                                >
                                                    {group.isActive ? 'Active' : 'Inactive'}
                                                </span>
                                            </td>

                                            <td className="px-6 py-4 whitespace-nowrap absolute mt-2">
                                                <ActionPopup
                                                    groupData={group}
                                                    onDelete={() => { handleDeleteTopic(group._id) }}
                                                    onEdit={() => { setOpenEditDialog(true); setSelectedEditGroup(group); }}
                                                />
                                            </td>
                                        </tr>
                                        {expandedGroups[group._id] && (
                                            <tr>
                                                <td colSpan={8}>
                                                    <div className="">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-50">
                                                                <tr>
                                                                    {[
                                                                        'Topic Name',
                                                                        'LinkedIn Posts',
                                                                        'Twitter Posts',
                                                                        'LinkedIn Scrape',
                                                                        'Twitter Scrape',
                                                                        'LinkedIn Status',
                                                                        'Twitter Status',
                                                                    ].map((item) => (
                                                                        <th
                                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                                                                            key={item}
                                                                        >
                                                                            {item}
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {group.topics.map((topic) => (
                                                                    <tr key={topic._id}>
                                                                        <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                                            {topic.topic}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                                            {topic.linkedinPostCount}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                                            {topic.twitterPostCount}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                                            {topic.LinkedinlastScrape ? moment(topic.LinkedinlastScrape).format('MMM DD, YYYY, hh:mm A') : "-"}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                                            {topic.TwitterlastScrape ? moment(topic.TwitterlastScrape).format('MMM DD, YYYY, hh:mm A') : "-"}
                                                                        </td>
                                                                        <td className="px-6 py-4 text-sm">
                                                                            <span

                                                                                className={`px-2 py-1 rounded-full text-xs ${topic.linkedinStatus === 'pending'
                                                                                    ? 'bg-gray-100 text-gray-800'
                                                                                    : topic.linkedinStatus === 'completed'
                                                                                        ? 'bg-green-100 text-green-800'
                                                                                        : 'bg-yellow-100 text-yellow-800'
                                                                                    }`}
                                                                            >
                                                                                {topic.linkedinStatus}
                                                                            </span>
                                                                        </td>
                                                                        <td className="px-6 py-4 text-sm">
                                                                            <span
                                                                                className={`px-2 py-1 rounded-full text-xs ${topic.twitterStatus === 'pending'
                                                                                    ? 'bg-gray-100 text-gray-800'
                                                                                    : topic.twitterStatus === 'completed'
                                                                                        ? 'bg-green-100 text-green-800'
                                                                                        : 'bg-yellow-100 text-yellow-800'
                                                                                    }`}
                                                                            >
                                                                                {topic.twitterStatus}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>

                        </table>
                    </div>

                </div>


                <div className="mt-4 text-sm text-gray-600">
                    Page {data?.data?.page} of {Math.ceil(data?.data?.total / data?.data?.limit)}
                </div>
            </div>
        </MainLayout>
    );
};

export default Trending;
