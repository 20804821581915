import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import LogoutRequiredGuard from './guard/logoutRequiredGuard';
import Login from './pages/auth/login';

import { persistor, store } from './redux/store';

import { GOOGLE_CLIENT_KEY } from './utils/config';
import PaymentGuard from './guard/paymentGuard';

import AdminPage from './pages/admin';
import ServerInfo from './pages/server';
import AdminCouponsDashboard from './pages/coupon';
import TemplatePage from './pages/template';
import Trending from './pages/trending';
import TrendingMedia from './pages/trending/trendingMedia';
import TrendingPost from './pages/trending/trendingPost';
import ProfileUpload from './pages/Profile';



const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_KEY}>
            <QueryClientProvider client={queryClient}>
              <Router>
                <Routes>
                  {/* Auth Routes */}
                  <Route path="/login" element={<LogoutRequiredGuard><Login /></LogoutRequiredGuard>} />
                  {/* User routes */}
                  <Route path="/" element={<PaymentGuard><AdminPage /></PaymentGuard>} />
                  <Route path="/server-status" element={<PaymentGuard><ServerInfo /></PaymentGuard>} />
                  <Route path="/coupons-status" element={<PaymentGuard><AdminCouponsDashboard /></PaymentGuard>} />
                  <Route path="/template" element={<PaymentGuard><TemplatePage /></PaymentGuard>} />
                  <Route path="/trending" element={<PaymentGuard><Trending /></PaymentGuard>} />
                  <Route path="/trend-post" element={<PaymentGuard><TrendingPost /></PaymentGuard>} />
                  <Route path="/trend-media" element={<PaymentGuard><TrendingMedia /></PaymentGuard>} />
                  <Route path="/profile-upload" element={<PaymentGuard>< ProfileUpload /></PaymentGuard>} />

                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              </Router>
              <ToastContainer />
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
