import api from "../utils/api";

export const fetchTemplateCategory = async () => {
    try {
        const response = await api.get(`/template/type`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const fetchTemplates = async (data) => {
    try {
        const response = await api.post(`admin/template`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const saveTemplates = async (data) => {
    try {
        const response = await api.post(`admin/template/create`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const saveTemplatesCategory = async (data) => {
    try {
        const response = await api.post(`admin/template/category/create`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const deleteTemplate = async (data) => {
    try {
        const response = await api.post(`admin/template/delete`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const updateTemplateStatus = async (data) => {
    try {
        console.log(data);
        const response = await api.post(`admin/template/update`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
