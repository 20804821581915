import React, { useState } from "react";
import MainLayout from "../../layout/main";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchTemplateCategory, fetchTemplates, updateTemplateStatus } from "../../services/template.service";
import { toast } from "react-toastify";

export default function TemplatePriority({ isOpen, onClose }) {
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [isSaved, setIsSaved] = useState(false);
    const [templates, setTemplates] = useState([]);

    const { data: categories = [], isLoading } = useQuery({
        queryKey: ["template-type"],
        queryFn: () => fetchTemplateCategory(),
    });

    const handleCategoryChange = async (event) => {
        const value = event.target.value;
        setSelectedCategory(value);
        setSelectedTemplates([]); // Reset selected templates
        setIsSaved(false); // Reset saved status

        // Fetch templates for the selected category
        const response = await fetchTemplates({ TemplateId: value });
        setTemplates(response);

        // Automatically select free-trial templates
        const freeTrialTemplates = response
            .filter((template) => template.isFreeTrial)
            .map((template) => template._id);
        setSelectedTemplates(freeTrialTemplates);
    };

    const { mutate } = useMutation({
        mutationFn: updateTemplateStatus,
        onSuccess: () => {
            toast.success("Template status updated successfully!");
        },
        onError: () => {
            toast.error("Failed to update template status.");
        },
    });

    const handleTemplateToggle = async (templateId) => {
        setSelectedTemplates((prev) => {
            if (prev.includes(templateId)) {
                mutate({ templateId, status: false });
                return prev.filter((t) => t !== templateId);
            } else if (prev.length < 2) {
                mutate({ templateId, status: true });
                return [...prev, templateId];
            } else {
                console.log("Maximum limit reached.");
                return prev;
            }
        });
        setIsSaved(false); // Reset saved status
    };
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded shadow-lg p-6 w-full max-w-[40%] max-h-screen overflow-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Template Priority</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 font-semibold">✕</button>
                </div>

                {/* Category Selector */}
                <div className="mb-8 ">
                    <label
                        htmlFor="category-select"
                        className="block text-lg font-medium text-gray-700 mb-2"
                    >
                        Select a Category
                    </label>
                    <select
                        id="category-select"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        className="w-full border border-gray-300 rounded-md px-4 py-2 text-gray-700 shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none transition"
                    >
                        <option value="" disabled>
                            Choose a category
                        </option>
                        {categories.templateType?.map((category) => (
                            <option key={category._id} value={category._id}>
                                {category.templateType}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Templates */}
                {selectedCategory && (
                    <div>
                        <p className="text-gray-600 mb-4">
                            You can select up to <span className="font-medium">2 templates</span>.
                        </p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            {templates.map((template) => (
                                <div
                                    key={template._id}
                                    className={`flex items-center p-4 border rounded-lg transition ${selectedTemplates.includes(template._id)
                                        ? "border-blue-500 bg-blue-50"
                                        : "border-gray-300 bg-gray-100 hover:shadow-md"
                                        }`}
                                >
                                    <input
                                        type="checkbox"
                                        id={template._id}
                                        checked={selectedTemplates.includes(template._id)}
                                        onChange={() => handleTemplateToggle(template._id)}
                                        disabled={
                                            selectedTemplates.length >= 2 &&
                                            !selectedTemplates.includes(template._id)
                                        }
                                        className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                    <label
                                        htmlFor={template._id}
                                        className={`ml-3 text-base font-medium ${selectedTemplates.length >= 2 &&
                                            !selectedTemplates.includes(template._id)
                                            ? "text-gray-400 cursor-not-allowed"
                                            : "text-gray-800"
                                            }`}
                                    >
                                        {template.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Save Button
                {selectedCategory && (
                    <div className="flex justify-end mt-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2 hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                    </div>
                )} */}

                {/* Conditional Message for No Category Selected */}
                {!selectedCategory && (
                    <div className="text-center text-gray-500 py-6">
                        <p className="text-lg">Please select a category to view templates.</p>
                    </div>
                )}
            </div>
        </div>
    );
}
