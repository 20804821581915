import { useState } from "react";
import MainLayout from "../../layout/main";
import { storeProfile } from "../../services/profile.service";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function Home() {
    const [result, setResult] = useState(null); // To store search results
    const [source, setSource] = useState(1); // Default source is LinkedIn (1)

    // Validation schema using Zod
    const linkSchema = z.object({
        link: z.string().min(4, "Invalid profile link"),
    });

    // React Hook Form setup
    const {
        handleSubmit,
        register,
        setError,
        reset,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(linkSchema),
        defaultValues: { link: "" },
    });

    // React Query mutation for profile submission
    const { mutate, isLoading } = useMutation({
        mutationFn: storeProfile,
        onSuccess: (data) => {
            console.log(data, "1234567890")
            if(data.alreadyExist) {
                toast.warning("Profile already exists !");
            }
            else {
                toast.success("Profile added successfully!");
            }
            reset(); // Reset the form on success
        },
        onError: () => {
            setError("link", { type: "validate", message: "Invalid link" });
            toast.error("Something went wrong. Please try again.");
        },
    });

    // Form submission handler
    const onSubmit = (formData) => {
        mutate({ profileLinks: formData.link, source });
    };

    return (
        <MainLayout>
            <div className="container mx-auto p-6 max-w-2xl">
                <h2 className="text-2xl font-bold mb-6 text-gray-800">Profile Uploader</h2>
                <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-6 rounded-lg shadow-md">
                    {/* Select Platform */}
                    <div className="mb-6">
                        <label htmlFor="platform" className="block text-sm font-medium text-gray-700 mb-2">
                            Select Platform
                        </label>
                        <select
                            name="platform"
                            defaultValue="1"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={(e) => setSource(Number(e.target.value))}
                        >
                            <option value="1">LinkedIn</option>
                            <option value="2">Twitter</option>
                        </select>
                    </div>

                    {/* Profile Link Input */}
                    <div className="mb-6">
                        <label htmlFor="link" className="block text-sm font-medium text-gray-700 mb-2">
                            Link
                        </label>
                        <input
                            id="link"
                            name="link"
                            type="text"
                            placeholder="Enter profile link"
                            {...register("link")}
                            className={`w-full p-2 border ${errors.link ? "border-red-500" : "border-gray-300"
                                } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                        />
                        {errors.link && (
                            <p className="text-red-500 text-sm mt-2">{errors.link.message}</p>
                        )}
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                    >
                        {isLoading ? "Loading..." : "Save"}
                    </button>
                </form>
            </div>
        </MainLayout>
    );
}
